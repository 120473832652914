<template>
  <!-- 登录弹窗 -->
  <div class="log-modal m">
    <div class="page-top">
      <div class="close" @click="closeBtn"></div>
      登录
    </div>
    <div class="content">
      <!-- <div class="tab-titles">
        <span class="tab-title active">个人登陆</span>
        <span class="tab-title">企业登陆</span>
      </div> -->
      <div class="tab-content">
        <img class="img" :src="require('@/assets/head-default-personal.png')" />
        <template v-if="typeIndex == '1'">
          <div class="input-box user-name">
            <input type="number" v-model="logInfo.username" />
            <div class="tip" v-if="phoneErr">{{ phoneErr }}</div>
          </div>
          <div class="input-box password">
            <input
              :type="showPass ? 'text' : 'password'"
              v-model="logInfo.password"
            />
            <div
              class="show-pass"
              :class="showPass ? 'open' : 'close'"
              @click="showPass = showPass ? false : true"
            ></div>
            <div class="tip" v-if="passErr">{{ passErr }}</div>
          </div>
        </template>
        <template v-if="typeIndex == '2'">
          <div class="input-box user-name">
            <input type="number" v-model="regData.phone" />
            <div class="tip" v-if="phoneErr">{{ phoneErr }}</div>
          </div>
          <div class="input-box password ver-code">
            <input
              type="text"
              v-model="regData.code"
              autocomplete="new-password"
            />
            <div
              class="show-pass code_css"
              :class="this.timer ? 'get' : ''"
              @click="getCode"
            >
              {{ getCodeBtnText }}
            </div>
            <div class="tip" v-if="passErr">{{ passErr }}</div>
          </div>
        </template>
        <div class="ope">
          <!-- <label class="avoid-login" @click="avoidTheLogin = avoidTheLogin?false:true">
            <div class="radio" :class="avoidTheLogin?'active':''"></div>
            <span>7天内免登录</span>
          </label> -->
          <span class="forgot-pass" @click="changeType()">{{ loginType }}</span>
          <!-- <router-link to='/changePassword' class="forgot-pass">忘记密码</router-link> -->
        </div>
        <button class="log-btn" @click="logBtn">登录</button>
        <button class="reg-btn" @click="regBtn">注册</button>
        <!-- <div class="agreement">
          <label><input type="checkbox" v-model="agree">我已阅读并同意</label><router-link class="agreement-link" to='/'>《隐私政策》</router-link>和<router-link to='/' class="agreement-link">《用户协议》</router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ApiUrl } from "@/utils/util";
import { mapMutations } from "vuex";
export default {
  name: "Bottom",
  data() {
    return {
      loginType: "验证码登录",
      typeIndex: "1",
      timer: "", //倒计时
      getCodeBtnText: "获取验证码",
      agree: true,
      showPass: false,
      avoidTheLogin: "",
      logInfo: {
        //登录信息
        username: "", //手机号
        password: "", //密码
      },
      regData: {
        phone: "",
        code: "",
      },
      phoneErr: "", //手机号错误提示
      passErr: "", //登录报错提示
    };
  },
  methods: {
    ...mapMutations(["CloseMLoginModal", "ShowTipModal", "ClearUserInfo"]), //登录弹窗
    //登录
    logBtn() {
      if (this.typeIndex == "1") {
        if (this.logInfo.username == "") {
          this.phoneErr = "请填写手机号";
        } else {
          let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

          if (!reg.test(this.logInfo.username)) {
            this.phoneErr = "请填写正确的手机号";
            return;
          } else {
            this.phoneErr = "";
          }
        }

        this.$axios.post(`/v1/login/login`, this.logInfo).then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data.user;
            sessionStorage.setItem("auth", JSON.stringify(res.data.auth));
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.$axios
              .post(`/v1/px/loginRecord`, {}, { useLog: true })
              .then(() => {
                //登录记录
                location.reload();
                this.CloseMLoginModal();
              });
          } else {
            console.log('kdkkdkdkd')
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      } else {
        if (this.regData.phone == "") {
          this.phoneErr = "请填写手机号";
        } else {
          let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

          if (!reg.test(this.regData.phone)) {
            this.phoneErr = "请填写正确的手机号";
            return;
          } else {
            this.phoneErr = "";
          }
        }

        this.$axios.post(`/v1/login/loginCode`, this.regData).then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data.user;
            sessionStorage.setItem("auth", JSON.stringify(res.data.auth));
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.$axios
              .post(`/v1/px/loginRecord`, {}, { useLog: true })
              .then(() => {
                //登录记录
                location.reload();
                this.CloseMLoginModal();
              });
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      }
    },
    //注册按钮
    regBtn() {
      //this.logModal = false;
      this.CloseMLoginModal();
      this.$router.push("/registered/1");
    },

    // 获取验证码
    getCode() {
      if (this.regData.phone) {
        let url = "";

        this.$axios
          .post(`${ApiUrl}/common/sendSms`, {
            sms_code_use: "userCodeLogin",
            phone: this.regData.phone,
          })
          .then((res) => {
            this.codeErr = res.msg;

            if (res.code == 0) {
              let time = 60;

              this.getCodeBtnText = time + "秒后再获取";
              this.timer = setInterval(() => {
                time--;
                if (time == 0) {
                  this.getCodeBtnText = "获取验证码";
                  clearInterval(this.timer);
                  this.timer = "";
                } else {
                  this.getCodeBtnText = time + "秒后再获取";
                }
              }, 1000);
            }
          });
      } else {
        this.phoneErr = "请填写手机号码";
      }
    },
    // 切换登录方式
    changeType() {
      
      if (this.typeIndex == "1") {
        this.loginType = "密码登录";
        this.typeIndex = "2";
        this.regData.phone = this.logInfo.username
      } else {
        this.typeIndex = "1";
        this.loginType = "验证码登录";
        this.logInfo.username = this.regData.phone

      }
    },
    //忘记密码
    forgotPass() {
      this.CloseMLoginModal();
      this.$router.push("/userCenter/changePassword");
    },

    //关闭登录
    closeBtn() {
      let url = this.$route.fullPath;
      if (url.includes("userCenter")) {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("userInfo");
        this.ClearUserInfo();
        this.$router.push("/index");
        this.CloseMLoginModal();
      } else {
        this.CloseMLoginModal();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.log-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 8888;
  .page-top {
    height: 88px;
    line-height: 88px;
    font-size: 36px;
    color: #333;
    text-align: center;
    font-weight: bold;
    position: relative;
    .close {
      width: 88px;
      height: 88px;
      background: url(~@/assets/close-grey-ico.png) no-repeat center center;
      background-size: 30px auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .tab-content {
    box-sizing: border-box;
    padding: 88px 48px 0;
    .img {
      display: block;
      width: 156px;
      height: 156px;
      margin: 0 auto;
      margin-bottom: 88px;
    }
  }
  .input-box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    padding-left: 116px;
    position: relative;
    input {
      // width: 100%;
      padding: 25px 0;
      font-size: 28px;
      color: #333;
      &::placeholder {
        color: #999;
      }
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 28px;
      background-color: #d8d8d8;
      position: absolute;
      left: 90px;
      top: 50%;
      margin-top: -14px;
    }
    .tip {
      box-sizing: border-box;
      width: 100%;
      font-size: 24px;
      color: #cd1b14;
      position: absolute;
      top: 100px;
      left: 0;
    }
    &.user-name {
      padding-top: 0;
      margin-bottom: 70px;
      background: url(~@/assets/phone-grey-ico.png) no-repeat 24px center;
      background-size: auto 50px;
    }
    &.password {
      background: url(~@/assets/lock-open-ico.png) no-repeat 22px center;
      background-size: auto 50px;
      margin-bottom: 20px;
      input {
        // width: 80%;
        flex: 1;
      }
      .show-pass {
        width: 52px;
        height: 52px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -26px;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
      .code_css {
        width: 200px;
        text-align: right;
        line-height: 60px;
        color: #254ed4;
      }
      .code_css.get {
        color: #999;
      }
    }
    &.ver-code{
       background: url(~@/assets/code-ico.png) no-repeat 22px center;
      background-size: auto 50px;
    }
  }
  .ope {
    margin-bottom: 60px;
    overflow: hidden;
    .avoid-login,
    a {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #666;
    }
    .radio {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      margin-right: 10px;
      &.active {
        background: url(~@/assets/selected-ico.png) no-repeat center center;
        background-size: auto 24px;
      }
    }
    .forgot-pass {
      float: right;
    }
  }
  .reg-btn,
  .log-btn {
    width: 100%;
    height: 88px;
    line-height: 88px;
    border-radius: 6px;
    font-size: 28px;
    letter-spacing: 2px;
    margin-bottom: 14px;
  }
  .log-btn {
    background-color: #254ed4;
    color: #fff;
  }
  .reg-btn {
    background: #fff;
    border-radius: 6px;
    color: #333;
    border: 1px solid #e5e5e5;
  }
  .agreement {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    .agreement-link {
      color: #254ed4;
    }
  }
}
.forgot-pass {
  font-size: 24px;
  color: #666;
}
.from-box {
  margin: 0 auto;
  .input-box {
    box-sizing: border-box;
    width: 100%;
    padding-left: 80px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    position: relative;
    margin: 10px 0;
    input {
      // width: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 15px;
      background-color: #d8d8d8;
      position: absolute;
      top: 50%;
      left: 59px;
      margin-top: -7.5px;
    }
    &.enterprise-name {
      background: url(~@/assets/contact-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    &.user-name {
      background: url(~@/assets/user-ico.png) no-repeat 20px center;
      background-size: 24px auto;
    }
    &.user-phone {
      background: url(~@/assets/phone-grey-ico.png) no-repeat 18px center;
      background-size: 24px auto;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &.ver-code {
      padding-right: 100px;
      background: url(~@/assets/code-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    .get-ver-code {
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #254ed4;
      cursor: pointer;
      &.get {
        color: #999;
        cursor: default;
      }
    }
    &.password {
      padding-right: 60px;
      background: url(~@/assets/lock-close-ico.png) no-repeat 20px center;
      background-size: 24px auto;
      input::-ms-clear {
        display: none;
      }
      input::-ms-reveal {
        display: none;
      }
      .show-pass {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -14px;
        cursor: pointer;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
    .input-text {
      padding: 13px 0 12px;
    }
  }
  .tip-err {
    font-size: 12px;
    color: #cd1b14;
    // position: absolute;
    // left: 0;
    // bottom: -20px;
    //margin-bottom: 24px;
  }
}
</style>
