<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <router-view />
    <foot-menu v-if="footerState" :deepLogoUrl='deepLogoUrl' />
  </div>
</template>
<script>
import headerMenu from "@/components/pc/header.vue";
import footMenu from "@/components/pc/footer.vue";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: '',
      deepLogoUrl:'',
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
  },
  methods: {
    getWebsiteInfo() {
      //获取网站配置信息
      let url = "/v1/websiteInfo";
      if (window.location.host == "zzcl.kjcxchina.com") {
        url = "/v1/websiteInfo";
      }
      if (window.location.host == "zzjt.kjcxchina.com") {
        url = "/v1/groupWebsiteInfo";
      }

      this.$axios.post(url, { is_pc: 2 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
        document.getElementById("linIcon").href = res.data.site_info.site_icon;
        document.getElementById("titleName").innerHTML = res.data.site_info.name;
        this.logoUrl = res.data.site_info.site_logo || require('@/assets/pc/logo.png');
        this.deepLogoUrl = res.data.site_info.footer_logo || require('@/assets/pc/footer_logo.png');
      });
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.from-box {
  margin: 0 auto;
  .input-box {
    box-sizing: border-box;
    width: 100%;
    padding-left: 80px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    position: relative;
    margin: 10px 0;
    input {
      width: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 15px;
      background-color: #d8d8d8;
      position: absolute;
      top: 50%;
      left: 59px;
      margin-top: -7.5px;
    }
    &.enterprise-name {
      background: url(~@/assets/contact-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    &.user-name {
      background: url(~@/assets/user-ico.png) no-repeat 20px center;
      background-size: 24px auto;
    }
    &.user-phone {
      background: url(~@/assets/phone-grey-ico.png) no-repeat 18px center;
      background-size: 24px auto;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &.ver-code {
      padding-right: 100px;
      background: url(~@/assets/code-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    .get-ver-code {
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #254ed4;
      cursor: pointer;
      &.get {
        color: #999;
        cursor: default;
      }
    }
    &.password {
      padding-right: 60px;
      background: url(~@/assets/lock-close-ico.png) no-repeat 20px center;
      background-size: 24px auto;
      input::-ms-clear {
        display: none;
      }
      input::-ms-reveal {
        display: none;
      }
      .show-pass {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -14px;
        cursor: pointer;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
    .input-text {
      padding: 13px 0 12px;
    }
  }
  .tip-err {
    font-size: 12px;
    color: #cd1b14;
    // position: absolute;
    // left: 0;
    // bottom: -20px;
    //margin-bottom: 24px;
  }
}
//表格样式
.my-table {
  width: 100%;
  height: auto;
  border-radius: 1px;
  border: 1px solid #e5e5e5;
  .table-th,
  .table-tr {
    height: 78px;
    display: flex;
    align-items: center;
    background-color: #fff;
    .table-td {
      font-size: 14px;
      color: #666;
    }
  }
  .table-th {
    height: 64px;
    background-color: #f5f5f5;
    .table-td {
      color: #333;
    }
  }
  .table-td {
    text-align: center;
  }
}
</style>
