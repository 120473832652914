<template>
  <div class="footer">
    <div class="container_mine">
      <div class="left_img">
        <div class="logo">
          <img v-if="deepLogoUrl" :src="deepLogoUrl" alt="" />
          <!-- <img v-else src="@/assets/pc/footer_logo.png" alt="" /> -->
        </div>
        <div class="info_text">
          <!-- <p class="text">主办单位：中职创联(北京)教育科技集团有限责任公司</p> -->
          <!-- 技术支持：<b>中工科创</b> -->
          <div class="text flex">
            Copyright © 2015-2021 kskstudy.comAll Rights Reserved
            &nbsp;京ICP备2022020614号 &nbsp; &nbsp;
            <div style="width: 300px; margin: 0 auto; padding: 20px 0">
              <a
                target="_blank"
                href=" "
                style="
                  display: inline-block;
                  text-decoration: none;
                  height: 20px;
                  line-height: 20px;
                "
                ><img src="@/assets/pc/beian.png" style="float: left" />
                <p
                  style="
                    float: left;
                    height: 20px;
                    line-height: 20px;
                    margin: 0px 0px 0px 5px;
                    color: #939393;
                  "
                >
                  京公网安备 11010502050317号
                </p></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="right_phone">
        <p class="label">联系电话</p>
        <p class="phone">{{ dataInfo.phone }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  min-height: 262px;
  background: #252525;
  clear: both;
  .container_mine {
    width: 1200px;
    padding-top: 45px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left_img {
      div.logo {
        margin-bottom: 37px;
        img {
          width: 240px;
          height: auto;
        }
      }
      .info_text {
        .text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9b9ea0;
          margin-bottom: 28px;
          b {
            color: #fff;
          }
        }
      }
    }
    .right_phone {
      margin-top: 30px;
      p {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      p.phone {
        font-weight: bold;
        margin-top: 36px;
      }
    }
  }
}
</style>
