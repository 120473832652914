<template>
  <div class="header">
    <div class="cont_container flex">
      <div class="left_logo">
        <router-link to="/"
              > 
              <img v-if="logo" :src="logo" alt="" />
              <!-- <img v-else src="@/assets/pc/logo.png" alt="" /> -->
        </router-link>
       <!-- 复制表单链接 -->
      </div>
      <div class="menu_list">
        <ul class="flex">
          <li>
            <router-link active-class="activeMenu" to="/"
              >报考指南</router-link
            >
          </li>
          <!-- <li>
            <router-link active-class="activeMenu" to="/list"
              >学习中心</router-link
            >
          </li> -->
        </ul>
      </div>
      <template v-if="url != 'zzjt.kjcxchina.com'">
        <div v-if="userInfo && userInfo!=''" class="user-state" @mouseover="userOpeShow = true" @mouseleave="userOpeShow = false">
          <img v-if="userInfo && userInfo.avatar!=''" :src="userInfo.avatar" class="user-head-portrait">
          <img v-else :src="require('@/assets/head-default-personal.png')" class="user-head-portrait">
          <span class="user-name">{{userInfo.nickname || userInfo.phone}}</span>
          <ul class="user-list" v-show="userOpeShow">
            <li class="option user-data" @click="$router.push('/userCenter/material')">个人中心</li>
            <!-- <li class="option learning-record"><router-link to='/userCenter/learning'>学习记录</router-link></li> -->
            <li class="option exit" @click="exitBtn">退出</li>
          </ul>
        </div>
        <div class="right_btn flex" v-else>
          <p class="btn1 cursor" @click="$router.push('/registered')">注册</p>
          <p class="btn2 cursor" @click="headerLoginBtn()">登录</p>
        </div>
      </template>
      
    </div>
    <Tip :text="tipModal.text" :ico="tipModal.ico" :showBtns="tipModal.showBtns" v-if="tipModal.show" :fun="tipModal.fun" :confirmFun="tipModal.confirmFun" :confirmBtnText="tipModal.confirmBtnText" :cancelFun="tipModal.cancelFun" :cancelBtnText="tipModal.cancelBtnText" />

    <Login v-if="loginModal.show" />
  </div>
</template>

<script>
import Login from "@/components/pc/login.vue";
import Tip from "@/components/pc/tip.vue";

import { mapState, mapMutations } from "vuex";
export default {
  name: "header",
  data() {
    return {
      loginTip: "", //登录提示
      indexSerch: "",
      userOpeShow: false, //昵称下拉显示隐藏
      menuIndex: "首页",
      url:'',
      dataInfo:'',
    };
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  components: {
    Login,
    Tip
  },
  mounted() {
    this.GetUserInfo(); //获取用户信息
    let names = localStorage.getItem("menuName");
    if (names) {
      this.menuIndex = names;
      // sessionStorage.removeItem('menuName')
    }
     this.dataInfo = JSON.parse(localStorage.getItem('siteInfo'))
    this.url = window.location.host
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
    ]), //登录弹窗
    //header登录按钮
    headerLoginBtn() {
      this.ShowLoginModal(); //登录弹窗
    },
    goNext(data) {
      let set_info = JSON.parse(sessionStorage.getItem("siteInfo"));
      document.getElementById("titleName").innerHTML =
        data.name + " - " + set_info.name;
      this.menuIndex = data.name;
      localStorage.setItem("menuName", data.name);
    },
    //退出按钮
    exitBtn() {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("userInfo");
      this.ClearUserInfo();

      if (this.$route.path.includes("userCenter")) {
        this.$router.push("/index");
      } else {
        this.$router.go(0);
      }
    },
    //搜索
    indexSerchBtn() {
      let _this = this;
      this.$router.push({ name: "Serch", params: { text: _this.indexSerch } });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: #fff;
  height: 80px;
  .cont_container {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    justify-content: space-around;
    .left_logo{
      img{
        width: 177px;
        height: auto;
      }
    }
    .menu_list {
      flex: 1;
      margin: 0 30px;
      ul {
        display: flex;
        li {
          flex: 1;
          text-align: center;
          font-size: 18px;
        }
      }
    }
    .user-state{
  float: right;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .login-btn{
    color: #000;
    cursor: pointer;
    margin-right: 14px;
  }
  .reg-btn{
    display: inline-block;
    padding: 7px 17px;
    border-radius: 2px;
    background: #254ED4;
    color: #FFFFFF;
   
    cursor: pointer;
  }
  .user-head-portrait{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-list{
    position: absolute;
    padding: 10px 16px ;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
    right: 0;
    top: 50px;
    z-index: 99;
    .option{
      padding-left: 26px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      color: #666;
      white-space: nowrap;
      cursor: pointer;
      a{
        font-size: 12px;
        color: #666;
      }
      &.user-data{
        background: url(~@/assets/user-ico2.png) no-repeat;
        background-size: 24px;
        margin-bottom: 12px;
      }
      &.learning-record{
        background: url(~@/assets/edit-ico.png) no-repeat;
        background-size: 24px;
        margin-bottom: 16px;
      }
      &.exit{
        // border-top: 1px solid #E5E5E5;
        background: url(~@/assets/exit-ico.png) no-repeat 0 center;
        background-size: 24px;
        // padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}
    .right_btn {
      p {
        width: 70px;
        height: 38px;
        border-radius: 4px;
        text-align: center;
        line-height: 38px;
         font-size: 16px;
      }
      p.btn1 {
        background: #3f78ff;
        color: #fff;
      }
      p.btn2 {
        border: 1px solid #3f78ff;
        margin-left: 20px;
        color: #3f78ff;
      }
    }
  }
}
.activeMenu::before {
  content: "";
  width: 20px;
  height: 3px;
  display: block;
  margin: 0 auto;
  margin-top: 4px;
  background-color: #fff;
  border-radius: 30px;
}
.activeMenu {
  // color: ;
  color: #3f78ff;
  // padding-bottom: 5px;
  // border-bottom: 2px solid #0065df;
}
.activeMenu::after {
  content: "";
  width: 20px;
  height: 3px;
  display: block;
  margin: 0 auto;
  margin-top: 4px;
  background-color: #0065df;
  border-radius: 30px;
}
</style>
