import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/index'
  },

  {
    path: '/pc',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => {
          if(window.location.host == 'localhost:8080'){
            return import('@/views/pc/index.vue')
          }

          // if(window.location.host == 'zzcl.kjcxchina.com'){
          //   return import('@/views/pc/index.vue')
          // }
          if(window.location.host=='www.cvgedu.com'){
            return import('@/views/pc/group_index.vue')
          }else{
            return import('@/views/pc/index.vue')
          }
        },
      },
      //详情
      {
        path: '/project/detail',
        name: 'project/detail',
        component: () => import('@/views/pc/project/detail.vue'),
      },
      //详情
      {
        path: '/course/detail',
        name: 'course/detail',
        component: () => import('@/views/pc/course/detail.vue'),
      },
      {
        path: '/coursePlay/:id',
        name: 'course/coursePlay',
        component: () => import('@/views/pc/course/coursePlay.vue'),
      },
      //h5表单
      {
        path: '/form_data',
        name: 'form_data',
        component: () => import('@/views/m/form_data.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //注册
      {
        path: '/registered',
        name: 'registered',
        component: () => import('@/views/pc/registered.vue'),
      },
      {
        path: '/text',
        name: 'text',
        component: () => import('@/views/pc/text.vue'),
      }, {
        path: '/exam_detail',
        name: 'exam_detail',
        component: () => import('@/views/pc/exam_detail.vue'),
      },
       //集团首页
      // {
      //   path: '/group_index',
      //   name: 'group_Index',
      //   component: () => import('@/views/pc/group_index.vue'),
      // },
      //个人中心userCenter
      {
        path: '/userCenter',
        name: 'UerCenter',
        component: () => import('../views/pc/userCenter/index.vue'),
        redirect:'/userCenter/material',
        children: [
          //个人资料
          {
            path: 'material',
            name: 'Material',
            component: () => import('../views/pc/userCenter/material.vue'),
          },
          //学习记录
          {
            path: 'learning',
            name: 'Learning',
            component: () => import('../views/pc/userCenter/learning.vue'),
          },
          //账号安全
          {
            path: 'accountSecurity',
            name: 'AccountSecurity',
            component: () => import('../views/pc/userCenter/accountSecurity.vue'),
          },
          //购买记录
          {
            path: 'purchaseRecords',
            name: 'PurchaseRecords',
            component: () => import('../views/pc/userCenter/purchaseRecords.vue'),
          },
          // //购买详情
          // {
          //   path: 'purchaseRecords/info/:id',
          //   name: 'PurchaseInfo',
          //   component: () => import('../views/pc/userCenter/purchaseInfo.vue'),
          // },
          //班级信息classInfo
          {
            path: 'classInfo',
            name: 'ClassInfo',
            component: () => import('../views/pc/userCenter/classInfo.vue'),
          },
          //我的考试myTest
          {
            path: 'myTest',
            name: 'MyTest',
            component: () => import('../views/pc/userCenter/myTest.vue'),
          },
          //消息通知
          // {
          //   path: 'message',
          //   name: 'Message',
          //   component: () => import('../views/pc/userCenter/message.vue'),
          // },
          //我的收藏
          {
            path: 'collection',
            name: 'Collection',
            component: () => import('../views/pc/userCenter/collection.vue'),
          }
        ]
      },

 {
        path: '/video',
        name: 'video',
        component: () => import('@/views/pc/video.vue'),
      },
      // 认证
      {
        path: '/authentication',
        name: 'authentication',
        component: () => import('@/views/pc/authentication.vue'),
      },


    ]
  }
]
const routes_m = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/pc',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/index',
        name: 'Index',
        // component: () => import('@/views/m/index.vue'),
        component: () => {
          if(window.location.host == 'localhost:8080'){
            return import('@/views/m/index.vue')
          }

          // if(window.location.host == 'zzcl.kjcxchina.com'){
          //   return import('@/views/m/index.vue')
          // }
          if(window.location.host=='www.cvgedu.com'){
            return import('@/views/m/group_index.vue')
          }else{
            return import('@/views/m/index.vue')
          }
        },
      },
       //集团首页
      //  {
      //   path: '/group_index',
      //   name: 'group_Index',
      //   component: () => import('@/views/m/group_index.vue'),
      // },
       //h5表单
       {
        path: '/form_data',
        name: 'form_data',
        component: () => import('@/views/m/form_data.vue'),
      },
       //详情
       {
        path: '/project/detail',
        name: 'project/detail',
        component: () => import('@/views/m/project/detail.vue'),
      },
       {
        path: '/project/submit',
        name: 'project/submit',
        component: () => import('@/views/m/project/submit.vue'),
      },
      
      //录播详情
      {
        path: '/recordedInfo/:id',
        name: 'RecordedInfo',
        component: () => import('@/views/m/recordedInfo.vue')
      },
      //注册
      {
        path: '/registered/:type',
        name: 'Registered',
        component: () => import('@/views/m/registered.vue')
      },
      {
        path: '/text',
        name: 'text',
        component: () => import('@/views/m/text.vue'),
      },
      // 考试详情
      {
        path: '/exam_detail',
        name: 'exam_detail',
        component: () => import('@/views/m/exam_detail.vue'),
      },
      //个人中心userCenter
      {
        path: '/userCenter',
        name: 'UerCenter',
        component: () => import('@/views/m/userCenter/index.vue')
      },
      //个人资料
      {
        path: '/userCenter/material',
        name: 'Material',
        component: () => import('@/views/m/userCenter/material.vue'),
      },
      //修改个人资料
      {
        path: '/userCenter/edit/:type',
        name: 'Edit',
        component: () => import('@/views/m/userCenter/edit.vue'),
      },
      //我的班级
      {
        path: '/userCenter/myClass',
        name: 'MyClass',
        component: () => import('@/views/m/userCenter/myClass.vue'),
      },
      //我的班级
      {
        path: '/userCenter/classInfo/:id/:type',
        name: 'ClassInfo',
        component: () => import('@/views/m/userCenter/classInfo.vue'),
      },
      //学习记录
      {
        path: '/userCenter/learning',
        name: 'Learning',
        component: () => import('@/views/m/userCenter/learning.vue'),
      },
      //我的收藏
      {
        path: '/userCenter/collection',
        name: 'Collection',
        component: () => import('@/views/m/userCenter/collection.vue'),
      },
      //修改密码
      {
        path: '/userCenter/changePassword',
        name: 'ChangePassword',
        component: () => import('@/views/m/userCenter/changePassword.vue')
      },
      //获取定位
      {
        path: '/getAdr',
        name: 'ChangePassword',
        component: () => import('@/views/m/getAdr.vue')
      },
     
    ]
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
})

export default router
