<template>
  <!-- 登录弹窗 -->
  <div class="log-modal">
    <div class="content">
      <div class="top">
        <div class="close-log" @click="CloseLoginModal"></div>
        <img
          class="top-img"
          v-if="siteInfo && siteInfo.login_image"
          :src="siteInfo.login_image"
        />
        <img
          class="top-img"
          v-else
          :src="require('@/assets/log-modal-top-bg.png')"
        />
        <div class="tab-titles">
          <span
            class="tab-title"
            :class="{ active: loginType == 1 }"
            @click="loginType = 1"
            >密码登录</span
          >
          <span
            class="tab-title"
            :class="{ active: loginType == 2 }"
            @click="loginType = 2"
            >验证码登录</span
          >
        </div>
      </div>
      <div class="tab-content">
        <div v-if="loginType == 1">
          <div class="input-box user-name">
            <input type="text" v-model="logInfo.username" />
          </div>
          <div class="tip" v-if="phoneErr">{{ phoneErr }}</div>
          <div class="input-box password">
            <input
              :type="showPass ? 'text' : 'password'"
              v-model="logInfo.password"
            />
            <div
              class="show-pass"
              :class="showPass ? 'open' : 'close'"
              @click="showPass = showPass ? false : true"
            ></div>
          </div>
          <div class="tip" v-if="passErr">{{ passErr }}</div>
        </div>
        <div v-if="loginType == 2">
          <div class="input-box user-name">
            <input type="text" v-model="regData.phone"    autocomplete='new-password' />
          </div>
          <div class="tip" v-if="phoneErr">{{ phoneErr }}</div>
          <div class="input-box ver-code flex">
            <input
              type="text"
              class="input-text"
              placeholder="请输入验证码"
               autocomplete='new-password'
              v-model="regData.code"
              

            />
            <span
              class="get-ver-code"
              :class="this.timer ? 'get' : ''"
              @click="getCode"
              >{{ getCodeBtnText }}</span
            >
          </div>
          <div class="tip" v-if="passErr">{{ passErr }}</div>
        </div>
        <div class="ope">
          <!-- <label class="avoid-login" @click="avoidTheLogin = avoidTheLogin?false:true">
              <div class="radio" :class="avoidTheLogin?'active':''"></div>
              <span>7天内免登录</span>
            </label> -->
          <!-- <span class="forgot-pass" @click="forgotPass">忘记密码</span> -->
          <!-- <router-link to='/changePassword' class="forgot-pass">忘记密码</router-link> -->
        </div>
        <button class="log-btn" @click="logBtn">登录</button>
        <button class="reg-btn" @click="regBtn">注册</button>
        <!-- <div class="agreement">
          <input type="checkbox" v-model="agree" /> 我已阅读并同意<router-link
            class="agreement-link"
            to="/"
            >《隐私政策》</router-link
          >和<router-link to="/" class="agreement-link"
            >《用户协议》</router-link
          >
        </div> -->
      </div>
    </div>

    <!-- 弹出框 -->
    <div class="mask_main" v-if="shenHeState">
      <div class="main_box">
        <p class="close" @click="shenHeState = false">
          <!-- <img src="@/assets/close_code.png" alt="" /> -->
        </p>
        <p class="title">扫码添加运营专员好友，快速通过账号审核！</p>
        <!-- <p class="img"><img src="@/assets/code_img.png" alt="" /></p> -->
        <p class="tips" @click="goBack()">关闭</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { ApiUrl } from "@/utils/util";

export default {
  name: "Bottom",
  data() {
    return {
      loginType: 1, //1、个人登录，2、企业登录
      agree: true,
      showPass: false,
      avoidTheLogin: "",
      logInfo: {
        //登录信息
        username: "", //手机号
        password: "", //密码
      },
      regData:{
        phone:'',
        code:'',
      },
      phoneErr: "", //手机号错误提示
      passErr: "", //登录报错提示
      shenHeState: false,
      siteInfo: "", //网站信息
      getCodeBtnText: "获取验证码",
      timer: "", //倒计时
    };
  },
  mounted() {
    this.siteInfo = JSON.parse(sessionStorage.getItem("siteInfo"));
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal"]), //登录弹窗
    //登录
    logBtn() {
      if (this.loginType == 1) {
        if (this.logInfo.username == "") {
          this.phoneErr = "请填写手机号";
          return;
        } else {
          let reg = /^0?1[3-9][0-9]\d{8}$/;

          if (!reg.test(this.logInfo.username)) {
            this.phoneErr = "请填写正确的手机号";
            return;
          } else {
            this.phoneErr = "";
          }
        }

        this.$axios.post(`/v1/login/login`, this.logInfo).then((res) => {
          if (res.code == 0) {
            //this.logModal = false;
            this.userInfo = res.data.user;
            sessionStorage.setItem("auth", JSON.stringify(res.data.auth));
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));

            this.$axios
              .post(`/v1/px/loginRecord`, {}, { useLog: true })
              .then(() => {
                //登录记录
                if (
                  this.$route.name == "Registered" ||
                  this.$route.name == "WelcomeRegistered"
                ) {
                  this.CloseLoginModal();
                  this.$router.push("/index");
                } else {
                  this.$router.go(0); //刷新页面
                }
              });
          } else if (res.code == 2 || res.code == 3) {
            this.shenHeState = true;
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      } else {
        this.$axios.post(`/v1/login/loginCode`, this.regData).then((els) => {
          if (els.code == 0) {
            //this.logModal = false;
            this.userInfo = els.data.user;
            sessionStorage.setItem("auth", JSON.stringify(els.data.auth));
            sessionStorage.setItem("user", JSON.stringify(els.data.user));
            sessionStorage.setItem("userInfo", JSON.stringify(els.data.user));

            this.$axios
              .post(`/v1/px/loginRecord`, {}, { useLog: true })
              .then(() => {
                //登录记录
                if (
                  this.$route.name == "Registered" ||
                  this.$route.name == "WelcomeRegistered"
                ) {
                  this.CloseLoginModal();
                  this.$router.push("/index");
                } else {
                  this.$router.go(0); //刷新页面
                }
              });
          } else if (els.code == 2 || els.code == 3) {
            this.shenHeState = true;
          } else {
            this.ShowTipModal({
              text: els.msg, //提示弹窗内容
            });
          }
        });
      }
    },
    // 获取验证码
    getCode() {
      if (this.regData.phone) {
        let url = "";

        if (this.loginType == 2) {
          url = `/v1/login/sendSmsCode`;
        }

        this.$axios
          .post(`${ApiUrl}/common/sendSms`, {
            sms_code_use:'userCodeLogin',
            phone: this.regData.phone,
          })
          .then((res) => {
            this.codeErr = res.msg;

            if (res.code == 0) {
              let time = 60;

              this.getCodeBtnText = time + "秒后再获取";
              this.timer = setInterval(() => {
                time--;
                if (time == 0) {
                  this.getCodeBtnText = "获取验证码";
                  clearInterval(this.timer);
                  this.timer = "";
                } else {
                  this.getCodeBtnText = time + "秒后再获取";
                }
              }, 1000);
            }
          });
      } else {
        this.phoneErr = "请填写手机号码";
      }
    },
    //注册按钮
    regBtn() {
      //this.logModal = false;
      this.CloseLoginModal();
      this.$router.push("/registered");
    },
    //忘记密码
    forgotPass() {
      this.CloseLoginModal();
      this.$router.push("/changePassword");
    },
    goBack() {
      this.CloseLoginModal();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.log-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  .content {
    width: 420px;
    background-color: #fff;
    border-radius: 8px;
  }
  .top {
    width: 100%;
    height: 80px;
    // background: url(~@/assets/log-modal-top-bg.png) no-repeat;
    // background-size: 100% auto;
    position: relative;
    .top-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      position: absolute;
      z-index: 0;
    }
    .close-log {
      width: 25px;
      height: 30px;
      background: url(~@/assets/pc/close.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }
    .tab-titles {
      width: 100%;
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    .tab-title {
      font-size: 18px;
      color: #fff;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      margin: 0 17px;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: 900;
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 6px solid #fff;
          position: absolute;
          bottom: -20px;
          left: 30px;
          transform: rotate(45deg);
        }
      }
    }
  }
  .tab-content {
    box-sizing: border-box;
    width: 100%;
    height: 368px;
    padding: 34px 34px 30px;
    position: relative;
  }
  .input-box {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    background: #fff;
    //box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    padding-left: 60px;
    position: relative;
    overflow: hidden;
    &.ver-code {
      width: 100%;
      background: url(~@/assets/code-ico.png) no-repeat 12px center;
      background-size: 28px auto;
      input{
        flex: 1;
      }
    }
    .get-ver-code {
      // width: 120px;
      padding-right: 10px;
      font-size: 12px;
      color: #254ed4;
      cursor: pointer;
       &.get {
        color: #999;
        cursor: default;
      }
    }
    input {
      width: 100%;
      height: 42px;
      line-height: 42px;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      background-color: #d8d8d8;
      position: absolute;
      left: 45px;
      top: 15px;
    }
    &.user-name {
      padding-top: 0;
      margin-bottom: 46px;
      background: url(~@/assets/phone-grey-ico.png) no-repeat 12px center;
      background-size: auto 24px;
    }
    &.password {
      background: url(~@/assets/lock-open-ico.png) no-repeat 12px center;
      background-size: auto 24px;
      margin-bottom: 10px;
      input {
        width: 80%;
      }
      .show-pass {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -14px;
        cursor: pointer;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
  }
  .tip {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px 0 34px;
    font-size: 12px;
    color: #cd1b14;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 90px;
    left: 0;
  }
  .ope {
    overflow: hidden;
    margin-bottom: 24px;
    .avoid-login,
    a {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #666;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      //禁用双击选中
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      cursor: pointer;
    }
    .radio {
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      margin-right: 10px;
      &.active {
        // background: url(~@/assets/selected-ico.png) no-repeat center center;
        background-size: auto 14px;
      }
    }
  }
  .reg-btn,
  .log-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    font-size: 16px;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    letter-spacing: 2px;
    margin-bottom: 14px;
    cursor: pointer;
  }
  .log-btn {
    background-color: #254ed4;
    color: #fff;
    //box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
  }
  .reg-btn {
    background: #fff;
    border-radius: 6px;
    color: #333;
    border: 1px solid #e5e5e5;
  }
  .agreement {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input {
      margin-right: 4px;
      cursor: pointer;
    }
    .agreement-link {
      color: #254ed4;
    }
  }
}
.forgot-pass {
  float: right;
  font-size: 12px;
  color: #666;
  cursor: pointer;
}

// 审核弹出框
.mask_main {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  .main_box {
    width: 400px;
    height: 320px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p.close {
      margin: 20px;
      display: flex;
      justify-content: flex-end;
    }
    p.title {
      text-align: center;
      margin-top: 32px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    p.img {
      width: 130px;
      height: 130px;
      margin: 20px auto;
    }
    p.tips {
      text-align: center;
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
}
</style>
