<template>
  <!-- 登录弹窗 -->
  <div class="tip" :class="!showBtns?'noBtn':''">
    <div class="tip-content">
      <div class="tip-text">{{text}}</div>
      <div class="btns" v-if="showBtns">
        <button class="button gery" @click="cancelBtn">{{cancelBtnText}}</button>
        <button class="button blue" @click="confirmBtn">{{confirmBtnText}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: 'tipModal',
  props: {
    text: String,
    showBtns: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Number,
      default: 2000
    },
    ico: {
      type: String,
      default: 'fail'
    },
    fun: Function,
    confirmFun: Function,
    cancelFun: Function,
    confirmBtnText: {
      type: String,
      default: '确定'
    },
    cancelBtnText: {
      type: String,
      default: '取消'
    }
  },
  mounted(){
    if(!this.showBtns){
      let _this = this;
      setTimeout(() => {
        _this.CloseTipModal();
        if(_this.fun){
          _this.fun();
        }
      }, _this.showTime);
    }
  },
  methods: {
    ...mapMutations(['CloseTipModal']), //tip弹窗
    //取消按钮
    cancelBtn(){
      this.cancelFun ? this.cancelFun() : this.CloseTipModal();
    },
    confirmBtn(){
      this.confirmFun();
    }
  }
}
</script>

<style scoped lang="scss">
.tip{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
  .tip-content{
    box-sizing: border-box;
    width: 516px;
    padding: 48px 32px 24px;
    background: #FFF;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  }
  &.noBtn{
    .tip-content{
      width: auto;
      padding-top: 24px;
      // background: rgba(0, 0, 0, 1);
      color: #333;
    }
  }
  .tip-text{
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  .btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 0;
    .button{
      height: 48px;
      line-height: 48px;
      font-size: 28px;
      &.gery{
        background-color: #fff;
      }
      &.blue{
        padding: 0 16px;
      }
    }
  }
}
</style>
